import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Alerte from './Alerte';
import logecomApi from '../utils/axiosInstance';
const apiRoot = process.env.REACT_APP_API_ROOT

const ChangeMdp = () => {

    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const [messageErreur, setMessageErreur] = useState(null);

    const [passActuel, setPassActuel] = useState("");

    const [passNouveau, setPassNouveau] = useState("");
    const [typeNouveau, setTypeNouveau] = useState("password");
    const [colorNouveau, setColorNouveau] = useState("black");

    const [passConfirmation, setPassConfirmation] = useState("");
    const [typeConfirmation, setTypeConfirmation] = useState("password");
    const [colorConfirmation, setColorConfirmation] = useState("black");

    const [respecte, setRespecte] = useState(true);
    const [identique, setIdentique] = useState(true);
    const [nouveauAncienIdentiques, setNouveauAncienIdentiques] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const [quitter, setQuitter] = useState(false);

    const passEnum = { Actuel: 0, Nouveau: 1, Confirmation: 2 };
    const caracAutorises = "!@#$%&*()-=_+{};<>?";

    const putMdp = () => {
        if (actionWsEnCours) {
            return;
        }
        var row = {
            passActuel: passActuel,
            passNouveau: passNouveau,
            passConfirmation: passConfirmation,
        }
        logecomApi.put(apiRoot + 'usersettings/changemdp', row).then((res) => {
            setActionWsEnCoursEnCours(false);
            setPassActuel('');
            setPassNouveau('');
            setPassConfirmation('');
            localStorage.setItem('changement_mdp', 0);
            setQuitter(true);
            setMessageErreur("Mot de passe correctement changé")
        }).catch((error) => {
            setActionWsEnCoursEnCours(false);
            setMessageErreur(error.response.data.fault.detail)
        })

    }

    const setPass = (type, pass) => {
        let color = 'black';
        if (pass.match(/[a-z]+/) && pass.match(/[A-Z]+/) && pass.match(/[0-9]+/) && containsSpecialCharacter(pass) && pass.length > 7) {
            color = 'black';
            setRespecte(true);
        } else {
            color = 'red';
            setRespecte(false);
        }
        if (type === passEnum.Actuel) {
            setPassActuel(pass);
            setRespecte(true);
        } else if (type === passEnum.Nouveau) {
            setPassNouveau(pass);
            setColorNouveau(color);
        } else if (type === passEnum.Confirmation) {
            setPassConfirmation(pass);
            setColorConfirmation(color);
        }
    }

    function containsSpecialCharacter(str) {
        for (var i = 0; i < str.length; i++) {
            if (caracAutorises.indexOf(str[i]) !== -1) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        let valid = passActuel !== '' && passNouveau !== '' && colorNouveau === 'black' && passNouveau === passConfirmation;
        setFormValid(valid);
        setIdentique(passNouveau === passConfirmation);
        setNouveauAncienIdentiques(passActuel === passNouveau && passActuel !== '');
    }, [passActuel, passNouveau, colorNouveau, passConfirmation]);

    return (
        <React.Fragment>
            <Helmet><title>LOGeCOM - Mot de passe</title>

            </Helmet>
            <div className="layout-wrapper layout-1 layout-without-sidenav">
                <div className="layout-inner">

                    <div className="layout-container">
                        <div className="layout-content">

                            <div className="containerLogin" >

                                <div className="container flex-grow-1 mycontainer-p-y">
                                    <div style={{ marginBottom: '40px' }}>
                                        <span className="titre-section font-weight-bold">Changement de mot de passe</span>
                                        <div className="authentication-inner py-5">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="card authentication-wrapper">

                                                        <form className="my-5" onSubmit={(event) => event.preventDefault()}>
                                                            <div className="authentication-2 px-4">
                                                                {parseInt(localStorage.getItem('changement_mdp')) === 1 &&
                                                                    <div className="form-group">
                                                                        <strong style={{ color: 'red' }}>Votre mot de passe doit être changé</strong>
                                                                    </div>
                                                                }
                                                                <div className="form-group">
                                                                    <div>Merci de respecter les règles suivantes :</div>
                                                                    <span></span>
                                                                    <div>
                                                                        <ul>
                                                                            <li>Longueur minimum de 8 caractères</li>
                                                                            <li>Au moins une lettre majuscule</li>
                                                                            <li>Au moins une lettre minuscule</li>
                                                                            <li>Au moins un chiffre</li>
                                                                            <li>Au moins un caractère spécial parmi {caracAutorises}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="authentication-1 px-4">

                                                                <div className="form-group">
                                                                    <label className="form-label d-flex justify-content-between align-items-end">
                                                                        <div>Entrez votre mot de passe actuel</div>
                                                                    </label>
                                                                    <div className="changemdp">
                                                                        <input type="password"
                                                                            value={passActuel}
                                                                            placeholder="Mot de passe actuel"
                                                                            className="form-control"
                                                                            onChange={(event) => { setPass(passEnum.Actuel, event.target.value) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label d-flex justify-content-between align-items-end">
                                                                        <div>Entrez votre nouveau mot de passe</div>
                                                                    </label>
                                                                    <div className="changemdp">
                                                                        <input type={typeNouveau}
                                                                            value={passNouveau}
                                                                            style={{ marginBottom: '5px', color: colorNouveau }}
                                                                            placeholder="Nouveau mot de passe"
                                                                            className="form-control"
                                                                            onChange={(event) => { setPass(passEnum.Nouveau, event.target.value) }}
                                                                        />
                                                                        <span style={{ cursor: 'pointer' }} onClick={() => { setTypeNouveau(typeNouveau === "password" ? "input" : "password") }}>
                                                                            <div className="ion ion-md-eye d-block"></div>
                                                                        </span>
                                                                    </div>

                                                                    <div className="changemdp">
                                                                        <input type={typeConfirmation}
                                                                            value={passConfirmation}
                                                                            style={{ marginBottom: '5px', color: colorConfirmation }}
                                                                            placeholder="Confirmez le mot de passe"
                                                                            className="form-control"
                                                                            onChange={(event) => { setPass(passEnum.Confirmation, event.target.value) }}
                                                                        />
                                                                        <span style={{ cursor: 'pointer' }} onClick={() => { setTypeConfirmation(typeConfirmation === "password" ? "input" : "password") }}>
                                                                            <div className="ion ion-md-eye d-block"></div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {!respecte && <div className="form-group">
                                                                    <span style={{ color: 'red' }}>Le mot de passe ne respecte pas les régles</span>
                                                                </div>}
                                                                {!identique && respecte && <div className="form-group">
                                                                    <span style={{ color: 'red' }}>Le nouveau mot de passe et la confirmation doivent être identiques</span>
                                                                </div>}
                                                                {nouveauAncienIdentiques && respecte && <div className="form-group">
                                                                    <span style={{ color: 'red' }}>Le mot de passe actuel et le nouveau doivent être différents</span>
                                                                </div>}
                                                                <div className="form-group">
                                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                        <button disabled={!formValid} type="submit" className="btn btn-primary" onClick={putMdp}>Modifier</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Alerte
                isModalVisible={messageErreur}
                handleAlerte={() => { setMessageErreur(null); if (quitter) { window.location = "/"; } }}
                message={messageErreur}
            />

        </React.Fragment >
    );

}

export default ChangeMdp;