import React, { useEffect, useState } from 'react';
import { Col, Row, ButtonGroup, ToggleButton, Button } from "react-bootstrap"
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import Alerte from '../Alerte';
import DiscussionAdd from './DiscussionAdd';
import DocumentList from '../../Components/Document/DocumentList';
import DiscussionFicheModal from './DiscussionFicheModal';
import DiscussionFiche from './DiscussionFiche';
import logecomApi from "../../utils/axiosInstance";
import { dateSort, downloadCSV, patchFilterFactory } from "../../utils/utils";
import { useLocation } from 'react-router-dom';
import YesNo from '../../utils/YesNo';
const apiRoot = process.env.REACT_APP_API_ROOT

const DiscussionListTable = (props) => {
    const client = props.client;
    const elementRattache = props.elementRattache;
    const idElementRattache = props.idElementRattache;
    const showButtonAdd = (idElementRattache > 0);
    const [duration, setDuration] = useState(30);
    const [rowSelected, setRowSelected] = useState({});
    const [discussions, setDiscussions] = useState([]);
    const [messageAlerte, setMessageAlerte] = useState(null);
    const [titreElement, setTitreElement] = useState(false);
    const [idElementRattacheDiscussion, setIdElementRattacheDiscussion] = useState(0);
    const [elementRattacheDiscussion, setElementRattacheDiscussion] = useState("");
    const [documentList, setDocumentList] = useState(false);
    const [discussionAdd, setDiscussionAdd] = useState(false);
    const [discussionFiche, setDiscussionFiche] = useState(false);
    const [discussionOuverteExistante, setDiscussionOuverteExistante] = useState(null);
    //TODO lien externe
    //const [selectedRows, setSelectedRows] = useState([]);
    //const tableRef = useRef(null);

    const statuts = {
        TOUTES: "0",
        OUVERTES: "1",
        NONLUES: "2"
    }
    let paramsLink = useLocation();
    let filtreParams = paramsLink.state === undefined ? undefined : paramsLink.state.filtre;
    const [statut, setStatut] = useState(filtreParams ? filtreParams : statuts.OUVERTES);
    const [params, setParams] = useState({});
    const [nbOuvertes, setNbOuvertes] = useState(0);
    const [nbNonLues, setNbNonLues] = useState(0);
    const [nbToutes, setNbToutes] = useState(0);
    const [exportData, setExportData] = useState([]);

    const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
        setExportData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
                return filteredData
            }
            return prevData
        })
    })


    const statutOptions = [{ value: '1', label: 'Ouvert' }, { value: '2', label: 'Fermé' }];
    const filteredData = () => {
        if (discussions === undefined || discussions === "" || JSON.stringify(discussions) === "{}") {
            return [];
        } else {
            return discussions.filter(function (row) {
                //TODO lien externe
                /*if(idDiscussion > 0) {
                    return row.IDDISCUSSION === parseInt(idDiscussion);
                }*/
                if (idElementRattache > 0 || statut === statuts.TOUTES) {
                    return true;
                } else if (statut === statuts.OUVERTES) {
                    return row.Statut.toString() === statuts.OUVERTES;
                } else if (statut === statuts.NONLUES) {
                    return row.nbMessagesNonLu > 0;
                }
                return true;
            })
        }
    }
    const handleDocumentList = (idElementRattache, row) => {
        if (typeof idElementRattache === 'number') {
            setIdElementRattacheDiscussion(idElementRattache);
            setElementRattacheDiscussion(row.ElementRattache)
            setTitreElement(libelleElement(row));
        }
        setDocumentList(true);
    };

    const handleDiscussionAdd = () => {
        if (discussionAdd) {
            // La fenêtre d'ajout vient d'être fermée, on relit la liste des discussions
            getDiscussions();
            setDiscussionAdd(false);
        }
        else {
            let discussionOuverte = null;
            discussions.forEach(discussion => {
                if (discussion.Statut.toString() === statuts.OUVERTES) {
                    discussionOuverte = discussion;
                }
            });
            if (discussionOuverte) {
                setDiscussionOuverteExistante({ titre: "", IDDISCUSSION: discussionOuverte.IDDISCUSSION, Libelle: discussionOuverte.Libelle, dateReference: discussionOuverte.dateReference, ElementRattache: discussionOuverte.ElementRattache, IdElementRattache: discussionOuverte.IdElementRattache });
            } else {
                setDiscussionOuverteExistante(null);
                setDiscussionAdd(true);
            }
        }
    }
    const handleDiscussionFiche = () => {
        setDiscussionFiche(!discussionFiche);
        getDiscussions();
    }
    useEffect(() => {
        localStorage.setItem("page", "discussions")
        getDiscussions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, elementRattache, idElementRattache, duration, statut]);

    function getDiscussions() {
        if (!client || parseInt(client) === 0) {
            return;
        }
        var url = apiRoot + 'clients/' + client;
        if (idElementRattache) {
            url = url + '/discussionselement?ElementRattache=\'' + elementRattache + '\'&IdElementRattache=' + idElementRattache;
        } else {
            url = url + '/discussions?duree=' + (statut === statuts.TOUTES ? duration : 3650);
        }
        logecomApi.get(url).then((res) => {
            let nbOuvertes = 0;
            let nbNonLues = 0;
            let nbToutes = 0;
            res.data.forEach(discussion => {
                if (statut === statuts.TOUTES) {
                    nbToutes++
                }
                if (discussion.Statut.toString() === statuts.OUVERTES) {
                    nbOuvertes++;
                }
                if (discussion.nbMessagesNonLu > 0) {
                    nbNonLues++;
                }
            });
            setNbOuvertes(nbOuvertes);
            setNbNonLues(nbNonLues);
            setNbToutes(nbToutes);
            setDiscussions(res.data);
            setRowSelected({});
            //TODO lien externe
            /*if (idDiscussion > 0) {
                    const firstRow = res.data.find((discussion) => discussion.IDDISCUSSION === parseInt(idDiscussion));
                    setSelectedRows([firstRow.IDDISCUSSION]); // Sélectionne la première ligne
                    const rowIndex = 0;
                    const rowSelected = tableRef.current.props.data[rowIndex];
                    setRowSelected(
                        {
                            idDiscussion: rowSelected.IDDISCUSSION,
                            elementRattache: rowSelected.ElementRattache,
                            idElementRattache: rowSelected.IdElementRattache,
                            titre: libelleElement(rowSelected)
                        }
                    );
            }*/

        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }
    const ouvrirFiche = (row) => {
        setParams({ titre: libelleElement(row), idDiscussion: row.IDDISCUSSION, elementRattache: row.ElementRattache, idElementRattache: row.IdElementRattache });
        setDiscussionFiche(true);
    }
    const headerWidth = (width) => () => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const afficherMessageAlerte = (afficher, titre, message) => {
        if (afficher === true) {
            setMessageAlerte({ 'titre': titre, 'message': message });
        } else {
            setMessageAlerte(null);
        }

    }
    const nomElement = (key) => {
        var elementName = { "Order": "Commande", "Item": "Article", "Pre-Receipt": "Pré-réception", "Receipt": "Réception", "Invoice": "Facture", "Sav": "Sav" };
        return elementName[key];
    }
    const libelleElement = (row) => {
        let nom = nomElement(row.ElementRattache) + ' ' + row.Libelle;
        if (row.dateReference !== '') {
            nom += ' du ' + row.dateReference;
        }
        return nom;
    }

    // Définit les contrôles présents sur les lignes
    const controlsFormatter = (cell, row, rowIndex, nomColonne) => {
        if (nomColonne === 'Libelle') {
            let nom = libelleElement(row);
            return (
                strong(row, nom)
            )
        } else if (nomColonne === 'ICONS') {
            return (
                <div className="btn-group">
                    {!idElementRattache && row.docExiste === 1 ?
                        <button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} title='Documents rattachés' onClick={() => handleDocumentList(row.IdElementRattache, row)}>
                            <i className="ion ion-md-document d-block"></i>
                        </button>
                        : null}
                    {idElementRattache ?
                        <button type="button" className="btn btn-md btn-primary" style={{ marginRight: '5px' }} onClick={() => ouvrirFiche(row)}>
                            <i className="ion ion-md-eye d-block"></i>
                        </button>
                        : null}
                </div>)
        } else if (nomColonne === 'Statut') {
            return strong(row, statutOptions.find(statut => statut.value === cell.toString()).label);
        } else {
            return (
                strong(row, cell)
            )
        }
    }
    const strong = (row, value) => {
        if (row.dernierMessageGD && row.nbMessagesNonLu > 0) {
            return (
                <strong>{value}</strong>
            )
        } else {
            return (
                value
            )
        }
    }
    // La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
    const columns =
        [
            ["", "ICONS", 30, true], ["ID", "IDDISCUSSION", 0, false], ["Sujet", "Sujet", 180, true], ["Statut", "Statut", 80, true],
            ["Concernant", "Libelle", 180, true], ["Créée le", "CreeLe", 80, true], ["Créée par", "CreePar", 80, true]
            , ["Nbre messages", "nbMessages", 80, true], ["Nbre messages non lus", "nbMessagesNonLu", 80, true]
            , ["Dernier message le", "dernierMessageLe", 80, true], ["Dernier message par", "dernierMessagePar", 80, true]
            , ["Clôturé le", "ClotureLe", 80, true], ["Clôturé par", "CloturePar", 80, true]
        ]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                result.formatExtraData = result.dataField;
                result.formatter = controlsFormatter;
                if (['IDDISCUSSION'].includes(result.dataField)) {
                    result.hidden = true;
                } else if (['CreeLe', 'dernierMessageLe', 'ClotureLe'].includes(result.dataField)) {
                    result.sortFunc = dateSort;
                } else if (result.dataField === 'ICONS') {
                    result.isDummyField = true
                    result.CSVExport = false
                    result.formatter = controlsFormatter;
                    result.sort = false;
                    result.filter = undefined;
                    result.text = '';

                } else if (result.dataField === 'Libelle') {
                    result.formatter = controlsFormatter;
                    if (idElementRattache) {
                        result.hidden = true;
                    }
                } else if (result.dataField === 'Statut') {
                    result.filter = selectFilter({
                        options: statutOptions,
                        className: 'custom-select',
                        placeholder: 'Tous'
                    })

                }

                return result
            })
    const labelDuree = (duree) => {
        var label = '';
        switch (duree) {
            case 7:
            case 14:
            case 60:
            case 90:
                label = duree + ' j';
                break;
            case 30:
                label = '1 mois';
                break;
            case 180:
                label = '6 mois';
                break;
            case 365:
                label = '1 an';
                break;
            default:
                label = duree + ' j';
                break;
        }
        return label;
    }
    function LibPaginator() {
        return <div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(event) => { setDuration(event.target.value) }}	>
            {[14, 30, 60, 90, 180, 365].map((n) => { return duration === n | duration === n.toString() ? <label key={n} className="btn btn-secondary active focus"><input type="radio" name="selection-duree" value={n} /> {labelDuree(n)}</label> : <label key={n} className="btn btn-secondary"><input type="radio" name="selection-duree" value={n} /> {labelDuree(n)} </label> }
            )}
        </div>

    }
    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };
    const selectRow = () => {
        return {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            hideSelectAll: true,
            //TODO lien externe
            //selected: selectedRows,
            bgColor: idElementRattache ? '' : '#1901A0',
            style: idElementRattache ? { color: '' } : { color: 'white' },
            onSelect: (row) => {
                if (!idElementRattache) {
                    //TODO lien externe
                    //setSelectedRows([row.IDDISCUSSION]);
                    setRowSelected(
                        {
                            idDiscussion: row.IDDISCUSSION,
                            elementRattache: row.ElementRattache,
                            idElementRattache: row.IdElementRattache,
                            titre: libelleElement(row)
                        }
                    );
                }
            }
        }
    };
    return (
        <>
            <DiscussionAdd
                isModalVisible={discussionAdd}
                elementRattache={elementRattache}
                idElementRattache={idElementRattache}
                client={client}
                titre={props.titre}
                handleDiscussionAdd={handleDiscussionAdd}
                messageDebut={null}
            />
            <DiscussionFicheModal
                isModalVisible={discussionFiche}
                elementRattache={params.elementRattache}
                idElementRattache={params.idElementRattache}
                idDiscussion={params.idDiscussion}
                client={client}
                titre={params.titre}
                handleDiscussion={handleDiscussionFiche}
            />
            <DocumentList
                isModalVisible={documentList}
                elementRattache={elementRattacheDiscussion}
                idElementRattache={idElementRattacheDiscussion}
                client={client}
                titre={titreElement}
                handleDocumentList={() => setDocumentList(!documentList)}
            />

            <ToolkitProvider
                keyField="IDDISCUSSION"
                data={filteredData()}
                columns={columns}
                bootstrap4
                search
                columnToggle
            >
                {props => (
                    <div>
                        <Row className="align-items-center" style={{ marginBottom: '20px' }}>
                            <Col>{!idElementRattache && <span className="titre-section font-weight-bold">Discussions</span>}
                                <div className="header-controles">
                                    {showButtonAdd && <button type="button" className="btn btn-md btn-primary" style={{ marginRight: '5px', height: '38px' }} onClick={handleDiscussionAdd}>
                                        <i className="ion ion-md-add-circle d-block"></i>
                                    </button>}
                                    {!idElementRattache && statut === statuts.TOUTES && <LibPaginator />}
                                    <Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, columns, 'Discussions') }}>Export CSV</Button>

                                    {!idElementRattache && <ButtonGroup style={{ marginLeft: '5px' }} toggle className="blue">
                                        <ToggleButton
                                            type="checkbox"
                                            variant="secondary"
                                            checked={statut === statuts.TOUTES}
                                            value="1"
                                            onChange={() => setStatut(statuts.TOUTES)}
                                        >
                                            Toutes {nbToutes > 0 && <span>({nbToutes})</span>}
                                        </ToggleButton>
                                        <ToggleButton
                                            type="checkbox"
                                            variant="secondary"
                                            checked={statut === statuts.OUVERTES}
                                            value="1"
                                            onChange={() => setStatut(statuts.OUVERTES)}
                                        >
                                            Ouvertes ({nbOuvertes})
                                        </ToggleButton>
                                        <ToggleButton
                                            type="checkbox"
                                            variant="secondary"
                                            checked={statut === statuts.NONLUES}
                                            value="1"
                                            onChange={() => setStatut(statuts.NONLUES)}
                                        >
                                            Non lues ({nbNonLues})
                                        </ToggleButton>
                                    </ButtonGroup>}

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapTable
                                    {...props.baseProps}
                                    rowStyle={rowStyle}
                                    wrapperClasses="table-responsive"
                                    pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 20 })}
                                    filter={myTableFilterFactory()}
                                    selectRow={selectRow()}
                                    //TODO lien externe
                                    //ref={tableRef}
                                    id='DiscussionListTable'
                                />
                            </Col>
                            {!idElementRattache && rowSelected.idElementRattache > 0 ? <Col>
                                <DiscussionFiche
                                    elementRattache={rowSelected.elementRattache}
                                    idElementRattache={rowSelected.idElementRattache}
                                    idDiscussion={rowSelected.idDiscussion}
                                    client={client}
                                    titre={rowSelected.titre}
                                >

                                </DiscussionFiche>
                            </Col> : null}
                        </Row>
                    </div>
                )}
            </ToolkitProvider>
            <Alerte
                isModalVisible={messageAlerte !== null}
                handleAlerte={afficherMessageAlerte}
                titre={messageAlerte ? messageAlerte.titre : null}
                message={messageAlerte ? messageAlerte.message : null}
            >
            </Alerte>
            <YesNo show={discussionOuverteExistante} hide={() => { setDiscussionOuverteExistante(null) }} callbackfunction={ouvrirFiche} object={discussionOuverteExistante} title="Discussion" body={"Une discussion ouverte existe déjà. Ouvrir cette discussion ?"}></YesNo>

        </>
    )
}

export default DiscussionListTable;